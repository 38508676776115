/* Component Dependencies */
var bookingOffersTemplate = require('templates/bookingOffers.hbs');
var bookingOffersMobileAppTemplate = require('templates/bookingOffers-rwdMobileAppDownload.hbs');
var bookingOfferFindYourMoment = require('templates/bookingOffers-findYourMoment.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
    type: 'bookingOffers',
    template: {
        'bookingOffers': bookingOffersTemplate,
        'bookingOffers-rwdMobileAppDownload': bookingOffersMobileAppTemplate,
        'bookingOffers-findYourMoment': bookingOfferFindYourMoment
    }
});